// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-birdpage-js": () => import("/opt/build/repo/src/pages/birdpage.js" /* webpackChunkName: "component---src-pages-birdpage-js" */),
  "component---src-pages-builtwith-js": () => import("/opt/build/repo/src/pages/builtwith.js" /* webpackChunkName: "component---src-pages-builtwith-js" */),
  "component---src-pages-contact-thanks-js": () => import("/opt/build/repo/src/pages/contact-thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-friends-js": () => import("/opt/build/repo/src/pages/friends.js" /* webpackChunkName: "component---src-pages-friends-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-robotguardian-js": () => import("/opt/build/repo/src/pages/robotguardian.js" /* webpackChunkName: "component---src-pages-robotguardian-js" */),
  "component---src-pages-svg-animation-js": () => import("/opt/build/repo/src/pages/svg-animation.js" /* webpackChunkName: "component---src-pages-svg-animation-js" */)
}

